import React from 'react'
import Script from 'next/script'

const Scripts = () => {
  return (
    <>
      <Script
        id="klaviyo"
        strategy="afterInteractive"
        src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=VPYdS6`}
      />
    </>
  )
}

export default Scripts
