import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import styles from './ProductInfoBlocks.module.css'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  resetNextUuid,
} from 'react-accessible-accordion'

const ProductInfoBlocks = ({ slice }) => {
  const { items } = slice
  const { container } = slice.primary
  resetNextUuid();

  return (
    <div className={`${container && styles.wrapper} ${styles.productInfoBlocks}`}>
        <Accordion allowZeroExpanded>
          {items.map((item, index) => (
            <AccordionItem uuid={`productInfoBlock--${index}`} key={`productInfoBlock--${index}`} className={styles.blockItem}>
              <AccordionItemHeading className={styles.blockItemHeading}>
                <AccordionItemButton className={styles.blockItemButton}>
                  <div className={styles.blockItemToggle}>
                    <img
                      className={styles.blockItemToggle__img}
                      src={item.icon.url}
                      alt={item.icon.alt || 'blockItemToggle'}
                    />
                    <div className={styles.blockItemToggle__text}>
                      <p className={styles.blockItemToggle__heading}>
                        {item.heading[0].text}
                      </p>
                      <p> {item.subHeading[0].text}</p>
                    </div>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className={styles.blockItemPanel}>
                <div>
                  <PrismicRichText field={item.text} />
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
    </div>
  )
}

export default ProductInfoBlocks
