import React from 'react'
import { FulfilmentInfoIcon } from '@components/icons'
import Chat from '../../icons/Chat'
import clsx from 'clsx'

interface NoticeProps {
  icon?: keyof typeof iconsMap
  title?: string
  content: string | React.ReactNode
  colour?: string
  onClick?: () => void
}

const iconsMap = {
  fulfilmentInfo: FulfilmentInfoIcon,
  chat: Chat,
}

const Notice = ({
  icon,
  title,
  content,
  colour = 'default',
  onClick,
}: NoticeProps) => {
  const iconComponent = () => {
    if (!icon || !iconsMap[icon]) return null
    const Icon = iconsMap[icon]
    return <Icon />
  }

  if (!content) return null

  const className = clsx(
    {
      'border-brand-chrome': colour === 'default',
      'border-message-warning': colour === 'message-warning',
    },
    'rounded-l border-2'
  )

  return (
    <div className="flex bg-brand-polar-white rounded" onClick={onClick}>
      <div className={className} />
      <div className="p-4 flex">
        <div className="pt-1">{iconComponent()}</div>
        <div className="text-label text-text-subdued pl-3">
          {title && <h4>{title}</h4>}
          {content}
        </div>
      </div>
    </div>
  )
}

export default Notice
